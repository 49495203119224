import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { 
    setAddPortalId, 
    hideLoader, 
    setAlertPortal, 
    setAlertPortalCurrent, 
    setDataGroup2, 
    showLoader,
    toggleLoaderGroup
} from "../redux/actions/modalActions";
import {  
    setDataPortal, 
    setCurrentPortalId
} from "../redux/actions/appActions";
import { useGetGroupList } from "./groupHooks";
import portalApi from "../api/portalApi";

const useInit = () => {
    const portalCurrent = useSelector(state => state.app.portalCurrent); 
    const addPortalId = useSelector(state => state.modal.addPortalId); 
    const portal = new portalApi();
    const dispatch = useDispatch();

    return { 
        dispatch, 
        portal, 
        portalCurrent,
        addPortalId
    };
};

export const useGetPortalList = (url) => {
    const init = useInit(); 
    const getSelectGroupItems = useGetGroupList(init.addPortalId); 
    let newUrl = '';
    if (url) {
        if (!url.includes('https://')) {
            newUrl = 'https://' + url;
        } else {
            newUrl = url;
        }
    
        if (newUrl.slice(-1) === '/') {
            newUrl = newUrl.slice(0, -1);                  
        }
    } 

    const { isLoading, error, data, refetch } = useQuery(
        "portal list",
        () => init.portal.getListPortals(newUrl),
        {
            onSuccess: async ({ data }) => {                            
                if(Array.isArray(data)) {  
                    if (newUrl === '' || newUrl === undefined) { //запрос при первой загрузке страницы
                        init.dispatch(setDataPortal(data));
                        data.forEach(item => {
                            if (item.url === init.portalCurrent.URL) init.dispatch(setCurrentPortalId(item.id));
                        });
                    } else { //запрос при добавлении портала 2
                        if (newUrl !== init.portalCurrent.URL) {
                            init.dispatch(setAlertPortalCurrent(false));                            
                            if (data.length > 0) {
                                await init.dispatch(setAddPortalId(data[0].id));
                                init.dispatch(setAlertPortal(false));
                                init.dispatch(showLoader());
                                await getSelectGroupItems.refetch();
                                init.dispatch(toggleLoaderGroup(false));
                            } else {
                                init.dispatch(hideLoader());
                                init.dispatch(toggleLoaderGroup(false));
                                init.dispatch(setAlertPortal(true));
                                init.dispatch(setDataGroup2([]));
                            }
                        } else {
                            init.dispatch(toggleLoaderGroup(false));
                            init.dispatch(setAlertPortalCurrent(true));
                            init.dispatch(setAlertPortal(false));
                            init.dispatch(setDataGroup2([]));
                        }
                        
                    }                    
                } 
            },
            enabled: false
        }
    );

    return {
        isLoading,
        error,
        data,
        refetch,
        dispatch: init.dispatch,
    };
};