/*REACT*/
import React from 'react';

/*REDUX*/
import { useDispatch, useSelector } from 'react-redux';
import { toggleModalSureDelete } from '../redux/actions/appActions';

/*MUI*/
import { Box, Button, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

/*COMPONENTS*/
import ModalCustom from './common/ModalCustom';

export const useStyles = makeStyles((theme) => ({
  center: {
    textAlign: 'center'
  },
  btnBlue: {
    backgroundColor: '#3f51b5 !important',
    marginLeft: 'auto'
  },
  btnRedBordered: {
    backgroundColor: '#f50057 !important',
  },
  btnDisabled: {
    backgroundColor: '#cccccc !important',
  },
}));

export default function ModalSureDelete({ deleteHandler }) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const open = useSelector(state => state.app.modalSureDelete);

    const handleClose = () =>  dispatch(toggleModalSureDelete(false));
    const handleDelete = () => deleteHandler();

    return (
      <ModalCustom
        open={ open }
        handleClose={ handleClose }
      >
        <Box className={ classes.center }>
            <Typography variant="body1" mb={3}>Вы действительно хотите удалить синхронизацию?</Typography>

            <Button 
              variant="contained"
              style={{marginRight: '20px'}} 
              className={ classes.btnRedBordered }
              onClick={ handleDelete } 
            >Да</Button>

            <Button 
              variant="contained" 
              className={ classes.btnBlue }
              onClick={ handleClose }
            >Нет</Button>                 
        </Box>
      </ModalCustom>  
    );
}