export const getIdGroup = (name, groupList) => {
    let id;

    if (groupList.length > 0) {
      groupList.forEach(item => {
        if (item.groupName === name) id = item.id;
      });
    }    
      
    return id;
}

export const getIdPortal = (name, data) => {
    let id, newUrl = '';
    if (name) {
        if (!name.includes('https://')) {
            newUrl = 'https://' + name;
        } else {
            newUrl = name;
        }
    
        if (newUrl.slice(-1) === '/') {
            newUrl = newUrl.slice(0, -1);                  
        }
    }

    if (data) {
        data.data.forEach(item => {
            if (item.url === newUrl) id = item.id;
        });
    }

    return id;
}