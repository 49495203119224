import {  
    SET_PORTAL_CURRENT,
    SET_SYNCHR_ID, 
    SET_PORTAL_DATA,
    SET_SYNCHR_DATA,
    SET_CURRENT_PORTAL_ID,
    SET_DATA_REQUEST,
    TOGGLE_SURE_DELETE,
    ALERT_DATA_CARD,
} from "../types";

export function setPortalCurrent(obj) {    
    return {
        type: SET_PORTAL_CURRENT,
        payload: obj
    }
}

export function setSynchrId(id) {    
    return {
        type: SET_SYNCHR_ID,
        payload: id
    }
}

export function setSynchrData(data) {    
    return {
        type: SET_SYNCHR_DATA,
        payload: data
    }
}

export function setCurrentPortalId(id) {    
    return {
        type: SET_CURRENT_PORTAL_ID,
        payload: id
    }
}

export function toggleModalSureDelete(bool) {    
    return {
        type: TOGGLE_SURE_DELETE,
        payload: bool
    }
}

export function setDataRequest(bool) {    
    return {
        type: SET_DATA_REQUEST,
        payload: bool
    }
}

export function setAlertDataCard(bool) {    
    return {
        type: ALERT_DATA_CARD,
        payload: bool
    }
}

export function setDataPortal(portals) {    
    return {
        type: SET_PORTAL_DATA,
        payload: portals
    }
}