import RestApi from "./restApi";

export default class groupApi extends RestApi {
    constructor() {
        let method = "group";
        super(method);
    }

    getGroupList = async (portalId) => { //список групп по айди портала
        const result = await this.sendRequest("getGroupList", {'portalId': portalId});
        return result;
    }
}