/*REACT*/
import React from 'react';
import { QueryClient, QueryClientProvider } from "react-query";

/*COMPONENTS*/
import ListSynchronizations from './components/ListSynchronizations';

function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false
      }
    }
  });

  return (
    <QueryClientProvider client={ queryClient }>
      <div className="App">
        <ListSynchronizations />
      </div>
    </QueryClientProvider>
  );
}

export default App;
