/*REACT*/
import React from 'react';

/*MUI*/
import { FormControl, Select, InputLabel, MenuItem } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
    select: {
        '& .MuiInputLabel-root.Mui-focused': {
            color: '#3f51b5 !important'
        },
        '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#3f51b5 !important'
        }
    }
  }));

export default function SelectItems(props) {
    const {
        value, 
        handleChange, 
        items, 
        idInput, 
        label,
        disabledProp 
    } = props;

    const classes = useStyles();    

    return (
        <>
            <FormControl fullWidth disabled={ disabledProp } className={ classes.select }>
                <InputLabel id={ idInput }>{ label }</InputLabel>
                <Select
                    labelId={ idInput }
                    id={ idInput + "-select" }
                    value={ value }
                    label={ label }
                    onChange={ handleChange }
                    
                    >
                    {items.map((item, index) => (
                        <MenuItem key={ index } value={ item.name }>{ item.name }</MenuItem>
                    ))}
                </Select>
            </FormControl>  
        </>
    )
}