/*REACT*/
import React from "react";

/*MUI*/
import { Stack } from "@mui/material";

export default function StackCustom(props) {
    const { children, options } = props;

    return (
        <Stack 
            direction={ options.direction && options.direction } 
            spacing={ options.spacing && options.spacing } 
            alignItems={ options.alignItems && options.alignItems } 
            justifyContent={ options.justifyContent && options.justifyContent }
            sx={ options.sx && options.sx }
        >
            { children }
        </Stack>
    )
}