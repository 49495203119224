import { 
    OPEN_MODAL, 
    CLOSE_MODAL, 
    CHANGE_GROUP1, 
    CHANGE_GROUP2,     
    SET_GROUP_DATA1,
    SET_GROUP_DATA2, 
    SET_ADD_PORTAL_ID,
    INPUT_PORTAL_2,
    ALERT_GROUP1,
    ALERT_GROUP,
    ALERT_TOKEN,
    ALERT_PORTAL,
    ALERT_PORTAL_CURRENT,
    SHOW_LOADER, 
    HIDE_LOADER,
    LOADER_GROUP
} from "./types";

const initialState = {
    open: false,
    group1: '',
    group2: '',    
    selectGroupItems: [],
    selectGroupItems2: [],
    addPortalId: 0,
    inputPortal2: '',
    alertGroup1: false,
    alertGroup: false,
    alertPortal: false,
    alertToken: false,
    alertPortalCurrent: false,
    loader: false,
    loaderGroup: false,
}

export const modalReducer = (state = initialState, action) => {
    switch(action.type){
        case OPEN_MODAL:
            return {...state, open: true}
        case CLOSE_MODAL:
            return {...state, open: false}
        case CHANGE_GROUP1:
            return {...state, group1: action.payload}
        case CHANGE_GROUP2:
            return {...state, group2: action.payload}        
        case SET_GROUP_DATA1:
            return {...state, selectGroupItems: action.payload}
        case SET_GROUP_DATA2:
            return {...state, selectGroupItems2: action.payload}
        case SET_ADD_PORTAL_ID:
            return {...state, addPortalId: action.payload}
        case INPUT_PORTAL_2:
            return {...state, inputPortal2: action.payload}
        case ALERT_GROUP:
            return {...state, alertGroup: action.payload}
        case ALERT_GROUP1:
            return {...state, alertGroup1: action.payload}
        case ALERT_TOKEN:
            return {...state, alertToken: action.payload}
        case ALERT_PORTAL:
            return {...state, alertPortal: action.payload}
        case ALERT_PORTAL_CURRENT:
            return {...state, alertPortalCurrent: action.payload}
        case SHOW_LOADER:
            return {...state, loader: true}
        case HIDE_LOADER:
            return {...state, loader: false}
        case LOADER_GROUP:
            return {...state, loaderGroup: action.payload}
        default: return state;
    }
}