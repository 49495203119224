import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { setDataGroup1, setDataGroup2, setAlertGroup, hideLoader, setAlertGroup1 } from "../redux/actions/modalActions";
import groupApi from "../api/groupApi";

const useInit = () => {
    const addGroup = new groupApi();
    const dispatch = useDispatch();
    const portalCurrentId = useSelector(state => state.app.portalCurrentId);
    const portalInput = useSelector(state => state.modal.inputPortal2); 
    const addPortalId = useSelector(state => state.modal.addPortalId);

    return { 
        dispatch, 
        addGroup, 
        portalCurrentId,
        addPortalId,
        portalInput
    };
};

export const useGetGroupList = () => {
    const init = useInit();
    const portalId = (init.addPortalId === 0) ? init.portalCurrentId : init.addPortalId;   

    const { isLoading, error, data, refetch } = useQuery(
        "group list",
        () => init.addGroup.getGroupList(portalId),
        {
            onSuccess: ({ data }) => {
                if (data.length === 0) {
                    init.dispatch(setAlertGroup1(true));
                } else {
                    const newData = data.filter(item => item.groupName);

                    if (init.portalCurrentId === portalId) {
                        if (Array.isArray(data) && data[0] !== 'портал не найден') {
                            init.dispatch(setDataGroup1(newData));
                        }
                    } else {
                        if (Array.isArray(data) && data[0] !== 'портал не найден') {
                            init.dispatch(setAlertGroup(false));
                            init.dispatch(setDataGroup2(newData));
                            init.dispatch(hideLoader());
                        } else {
                            init.dispatch(hideLoader());
                            init.dispatch(setAlertGroup(true));
                            init.dispatch(setDataGroup2([]));
                        }
                    } 
                }                
            },
            enabled: false
        }
    );

    return {
        isLoading,
        error,
        data,
        refetch,
        dispatch: init.dispatch,
    };
};