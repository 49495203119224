import { 
    HIDE_LOADER_SYNC, 
    SET_PROCESS_SYNC, 
    SET_PROGRESS, 
    SHOW_LOADER_SYNC, 
    SUCCESS_SYNC, 
    TOGGLE_SYNC_ERROR 
} from "../types";

export function setSuccess(bool) {    
    return {
        type: SUCCESS_SYNC,
        payload: bool
    }
}

export function setProgress(val) {    
    return {
        type: SET_PROGRESS,
        payload: val
    }
}

export function setProcessSync(bool) {    
    return {
        type: SET_PROCESS_SYNC,
        payload: bool
    }
}

export function toggleSyncError(data) {    
    return {
        type: TOGGLE_SYNC_ERROR,
        payload: data
    }
}

export function showLoaderSync() {    
    return {
        type: SHOW_LOADER_SYNC
    }
}

export function hideLoaderSync() {    
    return {
        type: HIDE_LOADER_SYNC
    }
}