/*REACT*/
import React from "react";

/*MUI*/
import { Box, CircularProgress } from "@mui/material";
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
    loaderWrap: {
      display: 'flex', 
      alignItems: 'center',     
    },
    loader: {
        color: "#3f51b5 !important"
    }
  }));

export default function CircleLoader({ scale = 1, style = {} }) {
    const classes = useStyles(); 

    return (
        <Box 
            className={ classes.loaderWrap } 
            sx={{ transform: `scale(${ scale })`, ...style }}
        >
            <CircularProgress  className={ classes.loader } />
        </Box>
    )
}