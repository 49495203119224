import RestApi from "./restApi";

export default class portalApi extends RestApi {
    constructor() {
        let method = "portals";
        super(method);
    }

    getListPortals = async (url = '') => { //список порталов
        const result = await this.sendRequest("getList", {"url": url});
        return result;
    }
}