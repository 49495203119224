import RestApi from "./restApi";

export default class synchrApi extends RestApi {
    constructor() {
        let method = "synchronization";
        super(method);
    }

    getListSyncInstans = async () => { //список синхронизаций (id, имя)
        const result = await this.sendRequest("getListSyncInstans");
        return result;
    }

    getGroupSyncGetList = async () => { //список групп синхронизации
        const result = await this.sendRequest("groupSyncGetList");
        return result;
    }

    addUpdateSyncInstans = async (fields) => { //добавить/изменить синхронизацию
        const result = await this.sendRequest("addUpdateSyncInstans", {'fields': fields});
        return result;
    }

    deleteSyncInstans = async (id) => { //удалить синхронизацию
        const result = await this.sendRequest("DeleteSyncInstans", {'id': id});
        return result;
    }

    groupSyncAdd = async (fields) => { //добавить группы и порталы в таблицу синхронизации (привязка)
        const result = await this.sendRequest("groupSyncAdd", {'fields': fields});
        return result;
    }

    sync = async (syncId, sourceGroupId, directionGroupId, page = 1) => { //синхронизация
        const result = await this.sendRequest("sync", { "syncId": syncId, "sourceGroupId": sourceGroupId, "directionGroupId": directionGroupId, "page": page});
        return result;
    }
    
    syncBatch = async (batchId, comments, checklists, elapseds) => { //получение данных по частям
        const result = await this.sendRequest("sendSyncCommandFromSyncBatchCommand", { "batchId": batchId, "commentsListSync": comments, "checklistsListSync": checklists, "elapsedsListSync": elapseds});
        return result;
    }

    groupSyncDelete = async (obj) => { //удаление привязки
        const result = await this.sendRequest("groupSyncDelete", { "filter": obj });
        return result;
    }
}