/*REACT*/
import React, { useEffect, useState } from 'react';

/*REDUX*/
import { useDispatch, useSelector } from 'react-redux';
import { 
  changeGroup1, 
  changeGroup2, 
  changeInputPortal2,
  closeModal,
  setAddPortalId,
  toggleLoaderGroup
} from '../redux/actions/modalActions';
import { setAlertDataCard, setSynchrId } from '../redux/actions/appActions';

/*HOOKS*/
import { useGroupSyncAdd } from '../hooks/synchrHooks';

/*HELPERS*/
import { getIdGroup, getIdPortal } from '../helpers/arrayHelpers';

/*MUI*/
import { Box, Button, Typography, Alert, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';

/*COMPONENTS*/
import SelectItems from './SelectItems';
import ModalCustom from './common/ModalCustom';
import StackCustom from './common/StackCustom';
import CircleLoader from './common/CircleLoader';

export const useStyles = makeStyles((theme) => ({
  btnBlue: {
    backgroundColor: '#3f51b5 !important',
    marginLeft: 'auto',
    minWidth: '108px !important',
  },
  btnRedBordered: {
    borderColor: '#f50057 !important',
    color: '#f50057 !important',
    lineHeight: '1.25 !important'
  },
  btnBlueDisabled: {
    backgroundColor: '#cccccc !important',
  },
  text: {
    width: '100%', 
    maxWidth: '100px',
    lineHeight: '1.3'
  },
  input: {
    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#3f51b5 !important'
    },
    '& .MuiInputLabel-root.Mui-focused': {
        color: '#3f51b5 !important'
    }    
  },
}));

export default function ModalSynchronization({ data }) {
  const { synchronizations, addSynchronization, getSyncTableData, clickedSynchrId, getSelectPortalItems } = data;

  const [disabledBtn, setDisablesBtn] = useState(true);
  const [newSelectGroupItems, setNewSelectGroupItems] = useState([]);
  const [newSelectGroupItems2, setNewSelectGroupItems2] = useState([]);
  const classes = useStyles();  
  const dispatch = useDispatch();
  const open = useSelector(state => state.modal.open);
  const group1 = useSelector(state => state.modal.group1);
  const group2 = useSelector(state => state.modal.group2);  
  const groupList1 = useSelector(state => state.modal.selectGroupItems); 
  const groupList2 = useSelector(state => state.modal.selectGroupItems2);
  const portalCurrent = useSelector(state => state.app.portalCurrent); 
  const portalCurrentId = useSelector(state => state.app.portalCurrentId);   
  const loader = useSelector(state => state.modal.loader);
  const alertGroup = useSelector(state => state.modal.alertGroup);
  const alertGroup1 = useSelector(state => state.modal.alertGroup1);
  const alertPortal = useSelector(state => state.modal.alertPortal);
  const alertPortalCurrent = useSelector(state => state.modal.alertPortalCurrent);
  const inputPortal2 = useSelector(state => state.modal.inputPortal2);
  const loaderGroup = useSelector(state => state.modal.loaderGroup);

  const addSyncGroup = useGroupSyncAdd([
    {
      "syncId" : Number(clickedSynchrId),
      "groupId": getIdGroup(group1, groupList1),
      "portalId": portalCurrentId,
      "readPermission": true,
      "writePermission": true
    },
    {
      "syncId" : Number(clickedSynchrId),
      "groupId": getIdGroup(group2, groupList2),
      "portalId": getIdPortal(inputPortal2, getSelectPortalItems.data),
      "readPermission": true,
      "writePermission": true
    }
  ]); 

  const addSynchr = async (e) => {
    e.preventDefault();
    await addSynchronization.refetch();
    await synchronizations.refetch();
    dispatch(setAlertDataCard(false));
    getSyncTableData(inputPortal2, group1, group2, getIdGroup(group1, groupList1), getIdGroup(group2, groupList2));  
    await addSyncGroup.refetch();
    handleClose();
    dispatch(setSynchrId(0));
  }  

  const handleClose = () => {
    dispatch(setAddPortalId(0));
    dispatch(closeModal(false));
  }

  const addPortalHandler = () => {
    dispatch(toggleLoaderGroup(true));
    getSelectPortalItems.refetch();
  }

  const handleChangeInput = (event) => dispatch(changeInputPortal2(event.target.value));
  const handleChangeGroup1 = (event) => dispatch(changeGroup1(event.target.value));
  const handleChangeGroup2 = (event) => dispatch(changeGroup2(event.target.value)); 

  useEffect(() => {
    if (groupList1.length > 0) {  
      const array = groupList1.map(item => {
        return {
          id: item.id,
          name: item.groupName
        }
      });  
      setNewSelectGroupItems(array);   
    } 
  }, [groupList1]);

  useEffect(() => {
    if (groupList2.length > 0) {  
      const array = groupList2.map(item => {
        return {
          id: item.id,
          name: item.groupName
        }
      });  
      setNewSelectGroupItems2(array);   
    } 
  }, [groupList2]);

  useEffect(() => {
    if (group1 !== '' && inputPortal2 !== '' && group2 !== '') {
      setDisablesBtn(false);
    }
  }, [group1, inputPortal2, group2, disabledBtn]);
  // console.log(newSelectGroupItems);
  return (      
    <ModalCustom
      open={ open }
      handleClose={ handleClose }
    >
      <Box className={ classes.modal }>
        <Box component="form" onSubmit={ addSynchr }>
          <StackCustom
              options={{
                  direction: "row", 
                  spacing: 2, 
                  alignItems: "center",
                  sx: {marginBottom: '16px'}
              }}
          >
            <Typography variant="body1" className={ classes.text }>Адрес портала 1:</Typography>
            <Typography variant="body1">{ portalCurrent.URL }</Typography>
          </StackCustom>

          <StackCustom
              options={{
                  direction: "row", 
                  spacing: 2, 
                  alignItems: "center",
                  sx: {marginBottom: '16px'}
              }}
          >
            <Typography variant="body1" className={ classes.text }>Группа портала 1:</Typography>
            { alertGroup1 ? 
              <Alert severity="error" sx={{ marginBottom: '16px'}}>Группы не найдены. Создайте новую группу.</Alert>
              :
              newSelectGroupItems.length > 0 ? 
                <SelectItems 
                    value={ group1 } 
                    handleChange={ handleChangeGroup1 }
                    items={ newSelectGroupItems || [] }
                    idInput='group1'
                    label='Группа'
                    disabledProp={ newSelectGroupItems.length > 0 ? false : true }
                /> :
                <CircleLoader scale={ 0.5 } />
            }
          </StackCustom>
          

          <StackCustom
              options={{
                  direction: "row", 
                  spacing: 2, 
                  alignItems: "center",
                  sx: {marginBottom: '16px'}
              }}
          >
              <Typography variant="body1" className={ classes.text }>Адрес портала 2:</Typography>
              <span style={{ color: 'grey' }}>https://</span>                
              <TextField 
                id="name" 
                value={ inputPortal2 } 
                label="example.bitrix24.ru" 
                variant="outlined" 
                size="small" 
                onChange={ handleChangeInput } 
                className={ classes.input }
                sx={{width: '100%'}}
              />
              <Button 
                variant="contained" 
                className={ classes.btnBlue }
                onClick={ addPortalHandler }
              >Добавить</Button> 
          </StackCustom>
          { alertPortal && <Alert severity="error" sx={{ marginBottom: '16px'}}>Такого портала не существует. Установите данное приложение на втором портале, и повторите попытку ещё раз.</Alert> }
          { alertPortalCurrent && <Alert severity="error" sx={{ marginBottom: '16px'}}>Адрес портала 2 не может совпадать с адресом текущего портала.</Alert> }

          { loaderGroup ? 
            <div style={{marginBottom: '16px'}}><CircleLoader scale={ 1 } /></div> :
            (groupList2.length > 0 && !alertPortal) &&  
              <StackCustom
                  options={{
                      direction: "row", 
                      spacing: 2, 
                      alignItems: "center",
                      sx: {marginBottom: '16px'}
                  }}
              >
                <Typography variant="body1" className={ classes.text }>Группа портала 2:</Typography>
                { loader ?
                    <CircleLoader scale={ 0.5 } /> :
                    <SelectItems 
                        value={ group2 } 
                        handleChange={ handleChangeGroup2 } 
                        items={ newSelectGroupItems2 || [] }
                        idInput='add-group'
                        label='Группа'
                    /> 
                }                            
              </StackCustom> 
          }
          { alertGroup && <Alert severity="error" sx={{ marginBottom: '16px'}}>Невозможно получить список групп. Установите данное приложение на втором портале, и повторите попытку ещё раз.</Alert> }
          {/* { alertGroup && <Alert severity="error" sx={{ marginBottom: '16px'}}>Ошибка токена. Невозможно получить список групп портала. Зайдите повторно в приложение на втором портале, и повторите попытку ещё раз.</Alert> } */}

          <Button 
            variant="contained" 
            type="submit" 
            className={ disabledBtn ? classes.btnBlueDisabled : classes.btnBlue }
            disabled={ disabledBtn }
          >Добавить синхронизацию</Button>           
        </Box> 
      </Box>
    </ModalCustom>
  );
} 