import { 
    SUCCESS_SYNC,
    SET_PROGRESS,
    SET_PROCESS_SYNC,
    TOGGLE_SYNC_ERROR,
    SHOW_LOADER_SYNC,
    HIDE_LOADER_SYNC
} from "./types";

const initialState = {
    loaderSync: false,
    success: false,
    progress: 0,
    processSync: false,
    syncError: {
        show: false,
        code: "",
        text: ""
    },
}

export const synchrReducer = (state = initialState, action) => {
    switch(action.type){
        case SUCCESS_SYNC:
            return {...state, success: action.payload}
        case SET_PROGRESS:
            // console.log('progress', state.progress);
            return {...state, progress: action.payload}
        case SET_PROCESS_SYNC:
            return {...state, processSync: action.payload}
        case TOGGLE_SYNC_ERROR:
            return {...state, syncError: action.payload}
        case SHOW_LOADER_SYNC:
            return {...state, loaderSync: true}
        case HIDE_LOADER_SYNC:
            return {...state, loaderSync: false}
        default: return state;
    }
}