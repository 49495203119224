import { 
    SET_PORTAL_DATA,
    SET_PORTAL_CURRENT,
    SET_SYNCHR_ID,
    SET_SYNCHR_DATA,
    SET_CURRENT_PORTAL_ID,
    SET_DATA_REQUEST,
    TOGGLE_SURE_DELETE,
    ALERT_DATA_CARD,
} from "./types";

const initialState = {
    selectPortalItems: [],
    portalCurrent: { URL: "" },
    portalCurrentId: 0,
    data: [],
    clickedSynchrId: 0,
    dataRequest: false,
    alertDataCard: false,
    modalSureDelete: false,
}

export const appReducer = (state = initialState, action) => {
    switch(action.type){
        case SET_PORTAL_DATA:
            return {...state, selectPortalItems: action.payload }
        case SET_PORTAL_CURRENT:
            return {...state, portalCurrent: action.payload}
        case SET_SYNCHR_ID:
            return {...state, clickedSynchrId: action.payload}
        case SET_SYNCHR_DATA:
            return {...state, data: action.payload}
        case SET_CURRENT_PORTAL_ID:
            return {...state, portalCurrentId: action.payload}
        case SET_DATA_REQUEST:
            return {...state, dataRequest: action.payload}
        case TOGGLE_SURE_DELETE:
            return {...state, modalSureDelete: action.payload}
        case ALERT_DATA_CARD:
            return {...state, alertDataCard: action.payload}
        default: return state;
    }
}