/*REACT*/
import React from "react";

/*MUI*/
import { Box, Modal } from "@mui/material";
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
    modal: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 700,
      borderRadius: '4px',
      background: '#ffffff',
      boxShadow: '0px 1px 47px rgba(0, 0, 0, 0.09)',
      padding: '24px',
    },
  }));

export default function ModalCustom(props) {
    const { children, open, handleClose } = props;
    const classes = useStyles();

    return (
        <Modal
            open={ open }
            onClose={ handleClose }
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="modal-group"
        >
            <Box className={ classes.modal }>
                { children }
            </Box>
        </Modal>
    )
}