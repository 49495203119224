import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import {  
    setAlertDataCard, 
    setDataRequest, 
    setSynchrData, 
    setSynchrId
} from "../redux/actions/appActions";
import {  
    hideLoaderSync,  
    setProcessSync, 
    setProgress, 
    setSuccess,  
    toggleSyncError 
} from "../redux/actions/syncActions";
import { useGetPortalList } from "./portalHooks";
import synchrApi from "../api/synchrApi";

const useInit = () => {
    const synchrData = useSelector(state => state.app.data); 
    const portalCurrentId = useSelector(state => state.app.portalCurrentId);
    const portalUrl = useSelector(state => state.modal.inputPortal2);
    const progressState = useSelector(state => state.synchr.progress);
    const clickedSynchrId = useSelector(state => state.app.clickedSynchrId);
    const group1 = useSelector(state => state.modal.group1);
    const group2 = useSelector(state => state.modal.group2);  
    const groupList1 = useSelector(state => state.modal.selectGroupItems); 
    const groupList2 = useSelector(state => state.modal.selectGroupItems2);
    const synchr = new synchrApi();
    const dispatch = useDispatch();

    return { 
        dispatch, 
        synchr, 
        synchrData,
        portalUrl,
        portalCurrentId,
        progressState,
        clickedSynchrId,
        group1,
        group2,
        groupList1,
        groupList2
    };
};

const handlerErrorsQuery = (data, init, showMessage = false) => {

    if (data.hasOwnProperty("error_description")) {
        let status = "error";
        if(data.hasOwnProperty("error")) {
            status = data.error;
        }
        if(showMessage) {init.message.show(data.error_description, status);}
        return false;
    }

    if(data.hasOwnProperty('error')) {
        if(showMessage) {init.message.show(data.error, "error");}
        return false;
    }

    if (data.hasOwnProperty('result') && data.result === null) {
        return false;
    }

    if(data === null) {
        return false;
    }

    return true;
}

export const useGetSynchrList = () => {
    const init = useInit();
    const selectPortalItems = useGetPortalList(init.portalUrl === '' ? '' : init.portalUrl);
    const connectionList = useGetSynchrGroupList();

    const { isLoading, error, data, refetch } = useQuery(
        "synchr list",
        () => init.synchr.getListSyncInstans(),
        {
            onSuccess: async ({ data }) => {   
               
                data.result.sort(function(a, b) { //сортируем id по возрастанию
                    if (a.id > b.id) {
                        return 1;
                    }
                    if (a.id < b.id) {
                        return -1;
                    }
                    return 0;
                });  

                const res = handlerErrorsQuery(data, init);
                
                if(res && Array.isArray(data.result)) {
                    await init.dispatch(setSynchrData(data.result));
                    await selectPortalItems.refetch();
                    connectionList.refetch();
                }
            },
        }
    );

    return {
        isLoading,
        error,
        data,
        refetch,
        dispatch: init.dispatch,
    };
};

export const useGetSynchrGroupList = () => {
    const init = useInit();
   
    const { isLoading, error, data, refetch } = useQuery(
        "synchr group list",
        () => init.synchr.getGroupSyncGetList(),
        {
            onSuccess: async ({ data }) => {
                const res = handlerErrorsQuery(data, init);
                let newData = [];

                if(res && Array.isArray(data.result)) {
                    newData = init.synchrData.map(sync => {  
                        data.result.forEach(item => {
                            if (Number(sync.projectId) === item.syncId) {
                                if (item.portalId !== init.portalCurrentId) {
                                    let newPortalName = '';                                    
                                    if (item.portalName.includes('https://')) {
                                        newPortalName = item.portalName.slice(8);
                                    } 

                                    sync = {
                                        ...sync,
                                        portal2Name: newPortalName,
                                        groupName2: item.groupName,
                                        groupId2: item.groupId
                                    }
                                } 

                                if (item.portalId === init.portalCurrentId) {
                                    sync = {
                                        ...sync,
                                        groupName1: item.groupName,
                                        groupId1: item.groupId
                                    }
                                }     
                            }  
                        });

                        return sync;
                    });

                    newData.forEach(sync => {
                        if (sync.projectId === init.clickedSynchrId) {
                            if (!sync.groupId1 || !sync.groupId2 || !sync.groupName1 || !sync.groupName2 || !sync.portal2Name) {
                                init.dispatch(setAlertDataCard(true));   
                            }
                        }
                    });
    
                    init.dispatch(setSynchrData(newData));                   
                    init.dispatch(setDataRequest(true));                         
                } 
            },
            enabled: false
        }
    );

    return {
        isLoading,
        error,
        data,
        refetch,
        dispatch: init.dispatch,
    };
};

export const useAddUpdateSyncInstans = (fields) => {
    const init = useInit();
  
    const { isLoading, error, data, refetch } = useQuery(
        "add update synchr",
        () => init.synchr.addUpdateSyncInstans(fields),
        {
            onSuccess: async({ data }) => {
                const res = handlerErrorsQuery(data, init);
                
                if (res) {
                    await init.dispatch(setSynchrId(data.result));
                }
            }, 
            enabled: false
        }
    );

    return { isLoading, error, data, refetch };
};

export const useDeleteSyncInstans = () => {
    const init = useInit();
    const deleteConnection = useDeleteConnection();
    
    const get = async (id) => {
        const result = await init.synchr.deleteSyncInstans(id);
        if (result.data.result) {
            deleteConnection.get(Number(id));
        }
        return result;
    }

    return {get};
};

export const useGroupSyncAdd = (fields) => {
    const init = useInit();

    const { isLoading, error, data, refetch } = useQuery(
        "add groups",
        () => init.synchr.groupSyncAdd(fields),
        {
            onSuccess: ({ data }) => {},
            enabled: false
        }
    );

    return { isLoading, error, data, refetch };
};

export const useSynronize = () => {
    const init = useInit();

    const get = async (syncId, sourceGroupId, directionGroupId) => {
        let page = 1;
        
        const result = await init.synchr.sync(syncId, sourceGroupId, directionGroupId, page);

        if (Array.isArray(result.data.items)) {        
            let countProgress = 0;
            let resultProgress = 10;
            let percentItteration = 0;
            let itemsProcessed = 0;
            let percentItterationPerPage = 0;
            const totalPages = result.data.totalPages;

            init.dispatch(setProgress(resultProgress)); //ставим прогресс 10
            
            for (let k = 1; k <= totalPages; k++) {
                page = k;
                const result2 = await init.synchr.sync(syncId, sourceGroupId, directionGroupId, page);

                if (Array.isArray(result2.data.items)) {
                    let countProgressPerPage = result2.data.items.length;

                    for (let i = 0; i < result2.data.items.length; i++) {
                        const item = result2.data.items[i];
                        const batchIds = item[0];
                        countProgress += batchIds.length; 
                    }
    
                    if (countProgress > 0) {
                        percentItterationPerPage = 90 / totalPages;
                        percentItteration = percentItterationPerPage / countProgressPerPage;
                        // percentItteration = (100 - resultProgress) / countProgress; 
                    }  
    
                    for (let i = 0; i < result2.data.items.length; i++) {
                        const item = result2.data.items[i];
                        const batchIds = item[0];
                        const commentSync = item[1];  
                        const checklistSync = item[2];
                        const elapsedSync = item[3];    
        
                        for (const id of batchIds) {                    
                            const progress = await init.synchr.syncBatch(id, commentSync, checklistSync, elapsedSync);
                            itemsProcessed++;
    
                            if((page === totalPages) && (itemsProcessed === countProgress)) {
                                //успех синхронизации
                                init.dispatch(setProgress(100));                               
                                init.dispatch(setSuccess(true)); 
                                init.dispatch(setProcessSync(false));
                                init.dispatch(hideLoaderSync());
                                init.dispatch(setProgress(0));
                            } else {
                                if (progress.data || typeof(progress.data) !== 'object') { 
                                    resultProgress += percentItteration;                            
                                    await init.dispatch(setProgress(resultProgress));
                                } else {
                                    init.dispatch(toggleSyncError({
                                        show: true,
                                        code: "",
                                        text: ""
                                    }));
                                } 
                            }   
                        } 
                    }
                } else {
                    init.dispatch(toggleSyncError({
                        show: true,
                        code: "",
                        text: ""
                    }));
                }
            }     
        } else {
            init.dispatch(toggleSyncError({
                show: true,
                code: "",
                text: ""
            }));
        }
         
        return result;
    }

    return {get};
};

export const useDeleteConnection = () => {
    const init = useInit();

    const get = async (id) => {
        const obj = await init.synchr.groupSyncDelete({'syncId': id}); 
        return obj;
    }

    return {get};
};