/*REACT*/
import React from 'react';

/*REDUX*/
import { useSelector } from 'react-redux';

/*MUI*/
import { LinearProgress, Typography, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  loader: {
      backgroundColor: 'rgba(63, 81, 181, 0.4) !important',
      '& .MuiLinearProgress-bar': {
        backgroundColor: '#3f51b5 !important',
      }
  },
}));

function LinearProgressWithLabel(props) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            props.value,
          )}%`}</Typography>
        </Box>
      </Box>
    );
  }

export default function Loader() {
  const classes = useStyles();
  const progress = useSelector(state => state.synchr.progress);

  return (
    <Box sx={{ width: '100%' }}>
      <LinearProgressWithLabel value={ progress } className={ classes.loader }/>
    </Box>
  );        
}