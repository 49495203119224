import { 
    ALERT_GROUP, 
    ALERT_GROUP1, 
    ALERT_PORTAL, 
    ALERT_PORTAL_CURRENT, 
    ALERT_TOKEN, 
    CHANGE_GROUP1, 
    CHANGE_GROUP2, 
    CLOSE_MODAL, 
    HIDE_LOADER, 
    INPUT_PORTAL_2, 
    LOADER_GROUP, 
    OPEN_MODAL, 
    SET_ADD_PORTAL_ID, 
    SET_GROUP_DATA1, 
    SET_GROUP_DATA2, 
    SHOW_LOADER 
} from "../types";

export function openModal() {    
    return {
        type: OPEN_MODAL
    }
}

export function closeModal() {    
    return {
        type: CLOSE_MODAL
    }
}

export function changeGroup1(value) {    
    return {
        type: CHANGE_GROUP1,
        payload: value
    }
}

export function changeGroup2(value) {    
    return {
        type: CHANGE_GROUP2,
        payload: value
    }
}

export function setDataGroup1(groups) {    
    return {
        type: SET_GROUP_DATA1,
        payload: groups
    }
}

export function setDataGroup2(groups) {    
    return {
        type: SET_GROUP_DATA2,
        payload: groups
    }
}

export function setAddPortalId(id) {    
    return {
        type: SET_ADD_PORTAL_ID,
        payload: id
    }
}

export function changeInputPortal2(string) {    
    return {
        type: INPUT_PORTAL_2,
        payload: string
    }
}

export function setAlertGroup(bool) {    
    return {
        type: ALERT_GROUP,
        payload: bool
    }
}

export function setAlertGroup1(bool) {    
    return {
        type: ALERT_GROUP1,
        payload: bool
    }
}

export function setAlertToken(bool) {    
    return {
        type: ALERT_TOKEN,
        payload: bool
    }
}

export function setAlertPortal(bool) {    
    return {
        type: ALERT_PORTAL,
        payload: bool
    }
}

export function setAlertPortalCurrent(bool) {    
    return {
        type: ALERT_PORTAL_CURRENT,
        payload: bool
    }
}

export function showLoader() {    
    return {
        type: SHOW_LOADER
    }
}

export function hideLoader() {    
    return {
        type: HIDE_LOADER
    }
}

export function toggleLoaderGroup(bool) {    
    return {
        type: LOADER_GROUP,
        payload: bool
    }
}