export const SET_PORTAL_CURRENT = 'APP/SET_PORTAL_CURRENT';
export const SET_SYNCHR_ID = 'APP/SET_SYNCHR_ID';
export const SET_ADD_SYNCHR_ID = 'APP/SET_ADD_SYNCHR_ID';
export const SET_SYNCHR_DATA = 'APP/SET_SYNCHR_DATA';
export const SET_SYNCHR_DATA_CONNECTION = 'APP/SET_SYNCHR_DATA_CONNECTION';
export const SET_CURRENT_PORTAL_ID = 'APP/SET_CURRENT_PORTAL_ID';
export const TOGGLE_SURE_DELETE = 'APP/TOGGLE_SURE_DELETE';
export const SET_DATA_REQUEST = 'APP/SET_DATA_REQUEST';
export const ALERT_DATA_CARD = 'APP/ALERT_DATA_CARD';
export const SET_PORTAL_DATA = 'APP/SET_PORTAL_DATA';

export const SHOW_LOADER = 'MODAL/SHOW_LOADER';
export const HIDE_LOADER = 'MODAL/HIDE_LOADER';
export const ALERT_GROUP1 = 'MODAL/ALERT_GROUP1';
export const ALERT_GROUP = 'MODAL/ALERT_GROUP';
export const ALERT_TOKEN = 'MODAL/ALERT_TOKEN';
export const ALERT_PORTAL = 'MODAL/ALERT_PORTAL';
export const ALERT_PORTAL_CURRENT = 'MODAL/ALERT_PORTAL_CURRENT';
export const INPUT_PORTAL_2 = 'MODAL/INPUT_PORTAL_2';
export const OPEN_MODAL = 'MODAL/OPEN_MODAL';
export const CLOSE_MODAL = 'MODAL/CLOSE_MODAL';
export const SET_ADD_PORTAL_ID = 'MODAL/SET_ADD_PORTAL_ID';
export const CHANGE_GROUP1 = 'MODAL/CHANGE_GROUP1';
export const CHANGE_GROUP2 = 'MODAL/CHANGE_GROUP2';
export const SET_GROUP_DATA1 = 'MODAL/SET_GROUP_DATA1';
export const SET_GROUP_DATA2 = 'MODAL/SET_GROUP_DATA2';
export const LOADER_GROUP = 'MODAL/LOADER_GROUP';

export const SHOW_LOADER_SYNC = 'SYNCHR/SHOW_LOADER_SYNC';
export const HIDE_LOADER_SYNC = 'SYNCHR/HIDE_LOADER_SYNC';
export const SUCCESS_SYNC = 'SYNCHR/SUCCESS_SYNC';
export const SET_PROGRESS = 'SYNCHR/SET_PROGRESS';
export const SET_PROCESS_SYNC = 'SYNCHR/SET_PROCESS_SYNC';
export const TOGGLE_SYNC_ERROR = 'SYNCHR/TOGGLE_SYNC_ERROR';