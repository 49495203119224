/*REACT*/
import React, { useEffect, useState } from 'react';

/*REDUX*/
import { useDispatch, useSelector } from 'react-redux';
import { 
    setAlertDataCard,
    setPortalCurrent, 
    setSynchrId, 
    toggleModalSureDelete,
} from '../redux/actions/appActions';
import { 
    setSuccess, 
    setProcessSync, 
    showLoaderSync
} from '../redux/actions/syncActions';
import { 
    changeGroup1, 
    changeGroup2, 
    openModal, 
    changeInputPortal2,
    setDataGroup2
} from '../redux/actions/modalActions';
import { useAddUpdateSyncInstans, useDeleteSyncInstans, useGetSynchrList, useSynronize } from '../hooks/synchrHooks';
import { useGetGroupList } from '../hooks/groupHooks';
import { useGetPortalList } from '../hooks/portalHooks';

/*MUI*/
import { 
    Typography, 
    Box, 
    IconButton, 
    CssBaseline, 
    Container, 
    Button, 
    Paper, 
    Grid, 
    Alert 
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import AddIcon from '@mui/icons-material/Add';
import { makeStyles } from '@mui/styles';

/*COMPONENTS*/
import ModalSynchronization from './ModalSynchronization';
import Loader from './Loader/Loader';
import StackCustom from './common/StackCustom';
import ModalSureDelete from './ModalSureDelete';
import CircleLoader from './common/CircleLoader';

export const useStyles = makeStyles((theme) => ({
    app: {
        minHeight: '100vh',
        background: '#eeeeee'
    },
    paper: {
        padding: 16,
        backgroundColor: '#fff',
    },
    paper2: {
        padding: '16px 32px'
    },
    btnBlue: {
        backgroundColor: '#3f51b5 !important'
    },
    card: {
        borderRadius: 4,
        boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)'
    },
    cardInner: {
        padding: 16
    },
    iconBlue: {
        padding: 4,
        '& svg': {
            fill: '#3f51b5'
        }        
    },
    iconDisabled: {
        padding: 4,
        '& svg': {
            fill: '#cccccc'
        }        
    },
    iconEdit: {
        width: "15px !important",
        height: "15px !important"
    },
    btnRedBordered: {
        borderColor: '#f50057 !important',
        color: '#f50057 !important'
    },
}));

export default function ListSynchronizations() {
    const [sData, setSData] = useState([]);
    const [namePortal, setNamePortal] = useState('');
    const [deletedSyncId, setDeletedSyncId] = useState(0);
    const classes = useStyles();
    const dispatch = useDispatch();  
    const synchronize = useSynronize();
    const deleteSynchr = useDeleteSyncInstans(); 
    const synchronizations = useGetSynchrList(); 
    const getSelectGroupItems = useGetGroupList();

    let synchrData = useSelector(state => state.app.data);   
    const portalCurrent = useSelector(state => state.app.portalCurrent);       
    const successSync = useSelector(state => state.synchr.success);  
    const processSync = useSelector(state => state.synchr.processSync);  
    const loaderSync = useSelector(state => state.synchr.loaderSync);
    const clickedSynchrId = useSelector(state => state.app.clickedSynchrId);
    const portalUrl = useSelector(state => state.modal.inputPortal2);
    const dataRequest = useSelector(state => state.app.dataRequest);
    const errorSync = useSelector(state => state.synchr.syncError);
    const alertDataCard = useSelector(state => state.app.alertDataCard);

    //присвоение текущего портала
    useEffect(() => {
        setNamePortal(window.url);
        dispatch(setPortalCurrent({
            URL: namePortal
        }));
        
    }, [dispatch, namePortal]);
     
    const getSelectPortalItems = useGetPortalList(portalUrl === '' ? '' : portalUrl); 
    const addSynchronization = useAddUpdateSyncInstans({
        "projectId" : (clickedSynchrId !== 0) ? clickedSynchrId : 0,
        "projectName" : "name project",
        "portalName" : namePortal
    });

    const handleOpen = () => {
        dispatch(openModal(true));  
        dispatch(changeInputPortal2(''));
        dispatch(changeGroup1(''));
        dispatch(changeGroup2(''));
        dispatch(setDataGroup2([]));
        getSelectGroupItems.refetch();
    }  

    const openDeleteHandler = async (id) => {  
        dispatch(toggleModalSureDelete(true));
        setDeletedSyncId(id);        
    }

    const deleteHandler = async () => {  
        await deleteSynchr.get(deletedSyncId);
        await synchronizations.refetch();
        dispatch(setAlertDataCard(false)); 
        dispatch(toggleModalSureDelete(false));
    }
  
    const getSyncTableData = (portal2, group1, group2, group1Id, group2Id) => {
        synchrData = synchrData.map(sync => {
            if (sync.projectId === clickedSynchrId) {
                return {
                    ...sync,
                    groupName1: group1,
                    portal2Name: portal2,
                    groupName2: group2,
                    groupId1: group1Id,
                    groupId2: group2Id 
                }
            }             
            return sync;           
        });      
    }

    const synchrToPortal = async (syncId, sourceGroupId, directionGroupId) => {
        dispatch(setSuccess(false));
        dispatch(setProcessSync(true));
        await dispatch(setSynchrId(syncId));
        dispatch(showLoaderSync());
        synchronize.get(syncId, sourceGroupId, directionGroupId);
    }

    //проверка синхронизаций текущего портала
    useEffect(() => {
        let array = [];
        array = synchrData.filter(sync => {
            return sync.portalName === portalCurrent.URL
        }
        );
        
        setSData(array);        
    }, [synchrData, portalCurrent.URL, alertDataCard]);

    return (
        <>
            <div className={ classes.app }>
                <CssBaseline />
                <Container maxWidth="lg" sx={{paddingTop: '50px'}}>   
                    <Paper elevation={0} className={ classes.paper } sx={{marginBottom: '24px'}}>
                        <Button 
                            variant="contained" 
                            onClick={ handleOpen } 
                            className={ classes.btnBlue }
                            startIcon={<AddIcon />}
                        >Добавить синхронизацию</Button>
                    </Paper>             
                    
                    <ModalSynchronization data={{ 
                        synchronizations, 
                        addSynchronization,
                        getSelectGroupItems, 
                        getSyncTableData, 
                        clickedSynchrId,
                        getSelectPortalItems 
                    }} /> 

                    {(sData.length > 0) && 
                        <Paper elevation={0} className={ classes.paper2 }>   
                            {!dataRequest ?   
                                <CircleLoader style={{ justifyContent: 'center' }} />                    
                                :
                                sData.map(synchr => {
                                    return (
                                    <Box key={ synchr.id } sx={{marginBottom: '20px', marginTop: '20px'}}>   
                                        <Grid container spacing={2} alignItems="center">
                                            <Grid item xs={12} sm container alignItems="center">
                                                <Grid item xs={12} md={5}>
                                                    <Box className={ classes.card }>
                                                        <Box className={ classes.cardInner }>
                                                            <StackCustom
                                                                options={{
                                                                    direction: { xs: 'column', md: 'row' }, 
                                                                    spacing: { xs: 0, md: 2 }, 
                                                                    alignItems: "center",
                                                                }}
                                                            >
                                                                <Typography variant="body1" sx={{minWidth: '75px'}}>Портал 1:</Typography>
                                                                <Typography variant="body1">{ (portalCurrent.URL.includes('https://') ? portalCurrent.URL.slice(8) : portalCurrent.URL) }</Typography>
                                                            </StackCustom> 
                                                            <StackCustom
                                                                options={{
                                                                    direction: { xs: 'column', md: 'row' }, 
                                                                    spacing: { xs: 0, md: 2 }, 
                                                                    alignItems: "center",
                                                                }}
                                                            >
                                                                <Typography variant="body1" sx={{minWidth: '75px'}}>Группа 1:</Typography>
                                                                {(synchr.groupName1 === undefined && !alertDataCard) ?
                                                                    <CircleLoader scale={ 0.4 } style={{ justifyContent: 'center', maxHeight: '24px' }} /> :
                                                                    <Typography variant="body1">{ (synchr.groupName1 === undefined) ? 'Не найдено' : synchr.groupName1 }</Typography> 
                                                                }  
                                                            </StackCustom>
                                                        </Box>
                                                        { (synchr.groupName1 === undefined && alertDataCard) && <Alert severity="error">Возникла ошибка. Невозможно получить данные.</Alert> }
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12} md={2}>
                                                    <StackCustom
                                                        options={{
                                                            direction: "row", 
                                                            spacing: 2, 
                                                            alignItems: "center",
                                                            justifyContent: "center"
                                                        }}
                                                    >
                                                        <IconButton aria-label="send" className={ ((clickedSynchrId === Number(synchr.projectId)) && (processSync || alertDataCard)) ? classes.iconDisabled : classes.iconBlue } onClick={ () => synchrToPortal(Number(synchr.projectId), synchr.groupId1, synchr.groupId2) }>
                                                            <ArrowForwardIosIcon />
                                                        </IconButton>
                                                        <IconButton aria-label="get" className={ ((clickedSynchrId === Number(synchr.projectId)) && (processSync || alertDataCard)) ? classes.iconDisabled : classes.iconBlue } onClick={ () => synchrToPortal(Number(synchr.projectId), synchr.groupId2, synchr.groupId1) }>
                                                            <ArrowBackIosIcon />
                                                        </IconButton>
                                                    </StackCustom>
                                                </Grid>
                                                <Grid item xs={12} md={5}>
                                                    <Box className={ classes.card }>
                                                        <Box className={ classes.cardInner }>
                                                            <StackCustom
                                                                options={{
                                                                    direction: { xs: 'column', md: 'row' }, 
                                                                    spacing: { xs: 0, md: 2 }, 
                                                                    alignItems: "center",
                                                                    sx: {marginBottom: '5px'}
                                                                }}
                                                            >
                                                                <Typography variant="body1" sx={{minWidth: '75px'}}>Портал 2:</Typography>
                                                                {(synchr.portal2Name === undefined && !alertDataCard) ?
                                                                    <CircleLoader scale={ 0.4 } style={{ justifyContent: 'center', maxHeight: '24px' }} /> :
                                                                    <Typography variant="body1">{ synchr.portal2Name === undefined ? 'Не найдено' : synchr.portal2Name }</Typography>
                                                                }   
                                                            </StackCustom>
                                                            <StackCustom
                                                                options={{
                                                                    direction: { xs: 'column', md: 'row' }, 
                                                                    spacing: { xs: 0, md: 2 }, 
                                                                    alignItems: "center",
                                                                }}
                                                            >
                                                                <Typography variant="body1" sx={{minWidth: '75px'}}>Группа 2:</Typography>
                                                                {(synchr.groupName2 === undefined && !alertDataCard) ?
                                                                    <CircleLoader scale={ 0.4 } style={{ justifyContent: 'center', maxHeight: '24px' }} /> :
                                                                    <Typography variant="body1">{ synchr.groupName2 === undefined ? 'Не найдено' : synchr.groupName2 }</Typography>
                                                                }
                                                            </StackCustom>
                                                        </Box>
                                                        { ((synchr.groupName2 === undefined || synchr.portal2Name === undefined) && alertDataCard) && <Alert severity="error">Возникла ошибка. Невозможно получить данные.</Alert> }
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                            <Grid item >
                                                <IconButton aria-label="delete" className={ classes.iconBlue } onClick={ () => openDeleteHandler(synchr.projectId) }>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Grid>
                                        </Grid> 

                                        { ((clickedSynchrId === Number(synchr.projectId)) && loaderSync && !errorSync.show) &&
                                            <>                                            
                                                <Loader />
                                                <Typography variant="caption">Пожалуйста, подождите, идет синхронизация. Она может занять несколько минут.</Typography> 
                                            </>                                            
                                        }    

                                        { ((clickedSynchrId === Number(synchr.projectId)) && successSync) &&
                                            <Alert severity="success" sx={{marginTop: '10px'}}>Синхронизация успешно завершена.</Alert>
                                        }     

                                        { ((clickedSynchrId === Number(synchr.projectId)) && errorSync.show) && 
                                            <Alert severity="error" sx={{marginTop: '10px'}}>Возникла ошибка { errorSync.code } { errorSync.text }. Пожалуйста попробуйте позже.</Alert>
                                        }                   
                                    </Box>
                                )})
                            }
                        </Paper> 
                    }  
                    <ModalSureDelete deleteHandler={ deleteHandler }/>          
                </Container>
            </div>
        </>    
    );
}
